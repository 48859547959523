@media (max-width: 86em) {
  .hero {
    max-width: 120rem;
  }

  .heading-primary {
    font-size: 3.6rem;
  }

  .grid-why-us {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
}

@media (max-width: 84em) {
  html {
    font-size: 56.25%;
  }

  .grd {
    column-gap: 4.8rem;
  }

  .heading-secondary {
    font-size: 3rem;
  }

  .heading-tertiary {
    row-gap: 2.4rem;
    font-size: 2.4rem;
  }

  .main-nav-list {
    gap: 3.2rem;
  }

  .hero {
    gap: 4.8rem;
  }
}

@media (max-width: 63em) {
  htm {
    font-size: 75%;
  }

  .header .compose-logo {
    padding-bottom: 1rem;
  }

  .logo {
    width: 75%;
  }

  .heading-primary, .hero-description {
    text-align: left;
  }

  .hero-text-box, .hero-img-box {
    text-align: center;
  }

  .section-hero {
    padding: 3.2rem 4.8rem;
  }

  .hero {
    grid-template-columns: 1fr;
  }

  .hero-img {
    width: 70%;
  }

  .logos {
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    column-gap: 1.2rem;
  }

  .grid-step, .grid-products {
    gap: 2.4rem;
  }

  .product-title {
    margin-bottom: 2.4rem;
    font-size: 1.8rem;
  }

  .product-data {
    font-size: 1.2rem;
  }

  .note {
    font-size: 1.2rem;
    font-weight: 700;
  }

  .note-title {
    font-size: 1.8rem;
  }

  .note-data {
    font-size: 1.4rem;
  }

  .grid-footer {
    column-gap: 4.8rem;
  }

  .btn-mobile-nav {
    z-index: 999;
    display: block;
  }

  .main-nav {
    -webkit-backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    width: 100%;
    height: 100vh;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    background-color: #ffffff80;
    justify-content: center;
    align-items: center;
    transition: all .5s ease-in;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(100%);
  }

  .nav-open .main-nav {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0);
  }

  .nav-open .icon-mobile-nav[name="close-outline"] {
    display: block;
  }

  .nav-open .icon-mobile-nav[name="menu-outline"] {
    display: none;
  }

  .main-nav-list {
    flex-direction: column;
    gap: 4.8rem;
  }

  .main-nav-link:link, .main-nav-link:visited {
    font-size: 3rem;
  }

  .cta-text-box {
    padding: 1.2rem;
  }

  .cta-form {
    column-gap: 1.2rem;
  }
}

@media (max-width: 53em) {
  .heading-primary {
    font-size: 3rem;
  }

  .container.grid.grid-4-cols {
    grid-template-columns: repeat(2, 1fr);
    gap: 2.4rem;
  }

  .grid-row-3-cols {
    grid-column: span 2;
  }

  .product-img {
    width: 50%;
  }

  .product-specifications li {
    font-size: 2.2rem;
  }

  .grid-footer {
    grid-template-columns: repate(6, 1fr);
  }

  .logo-col, .address-col {
    grid-column: span 3;
  }

  .nav-col {
    grid-area: 1 / span 2;
  }

  .cta-text-box {
    padding: 1.2rem;
  }

  .cta {
    grid-template-columns: 1.2fr 1fr;
  }

  .cta-form {
    grid-template-columns: 1fr;
  }

  .cta .heading-secondary {
    font-size: 2.4rem;
  }

  .cta-text {
    font-size: 1.6rem;
  }

  .hero-img {
    width: 100%;
  }
}

@media (max-width: 37em) {
  .grid-2-cols, .grid-why-us, .grid-3-cols {
    grid-template-columns: 1fr;
  }

  .start-order-btns {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .header .compose-logo {
    font-size: 2.4rem;
  }

  .log-title, .logo-activity {
    padding-top: 1rem;
  }

  .header .compose-logo p {
    font-size: 1.6rem;
  }

  .logo {
    padding-top: 1rem;
  }

  .btn, .btn:link, .btn:visited {
    padding: 2.4rem 3.6rem;
  }

  .section-hero {
    padding: 1.6rem 1.2rem;
  }

  .container.grid.grid-4-cols {
    grid-template-columns: 1fr;
  }

  .grid-row-3-cols {
    flex-direction: column;
    grid-column: span 1;
  }

  .hero-img {
    width: 100%;
  }

  .logos {
    grid-template-columns: 1fr 1fr;
  }

  .clients-imgs {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    margin-bottom: 2rem;
    display: grid;
  }

  .cta {
    grid-template-columns: 1fr;
  }

  .cta-image-box {
    height: 32rem;
    grid-row: 1;
  }

  .note-title, .abs-title {
    text-align: left;
    font-size: 2.4rem;
  }

  .note-data {
    text-align: left;
    padding-bottom: 1.2rem;
    font-size: 1.8rem;
  }

  .product {
    text-align: center;
  }

  .product-title {
    font-size: 2.8rem;
  }

  .product-specifications {
    align-items: center;
  }

  .section-cta {
    padding-bottom: 2.4rem;
  }

  .cta .heading-secondary {
    font-size: 2rem;
  }

  .cta-text {
    font-size: 1.4rem;
  }

  .footer {
    padding: 4rem 0;
  }
}

/*# sourceMappingURL=index.4062fc1a.css.map */
