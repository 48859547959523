/* rem and em do NOT depend on html font-size in media queries
Insted , 1rem = 1em = 16px */

/* -------------------------------------------------------------------------- */
/*                               BELOW 1344px /16px (Smaller desktops)        */
/* -------------------------------------------------------------------------- */
@media (max-width: 86em) {
  .hero {
    max-width: 120rem;
  }
  .heading-primary {
    font-size: 3.6rem;
  }

  .grid-why-us {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
}
/* -------------------------------------------------------------------------- */
/*                               BELOW 1200px /16px (Landscape Tablets)        */
/* -------------------------------------------------------------------------- */
@media (max-width: 84em) {
  html {
    /* Font size: 9px
    9px / 16px(default) = .5625(56.25%)
    Percentage of user's browserbfont-size settings
    1rem = 16px */
    font-size: 56.25%;
  }

  .grd {
    column-gap: 4.8rem;
  }
  .heading-secondary {
    font-size: 3rem;
  }
  .heading-tertiary {
    font-size: 2.4rem;
    row-gap: 2.4rem;
  }
  .main-nav-list {
    gap: 3.2rem;
  }
  .hero {
    gap: 4.8rem;
  }
}
/* -------------------------------------------------------------------------- */
/*                               BELOW 1000px /16px (Tablets)                 */
/* -------------------------------------------------------------------------- */
@media (max-width: 63em) {
  htm {
    /* Font size: 8px
    8px / 16px(default) = .5(50%)
    Percentage of user's browserbfont-size settings
    1rem = 16px */
    font-size: 75%;
  }
  .header .compose-logo {
    /* font-size: 2.4rem; */
    padding-bottom: 1rem;
  }

  .logo {
    width: 75%;
  }
  .heading-primary {
    text-align: left;
  }
  .hero-description {
    text-align: left;
  }

  .hero-text-box,
  .hero-img-box {
    text-align: center;
  }

  .section-hero {
    padding: 3.2rem 4.8rem;
  }
  .hero {
    grid-template-columns: 1fr;

    /* align-items: center; */
  }

  .hero-img {
    width: 70%;
  }

  .logos {
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    column-gap: 1.2rem;
  }

  .grid-step,
  .grid-products {
    column-gap: 2.4rem;
    row-gap: 2.4rem;
  }
  .product-title {
    font-size: 1.8rem;
    margin-bottom: 2.4rem;
  }
  .product-data {
    font-size: 1.2rem;
  }
  .note {
    font-size: 1.2rem;
    font-weight: 700;
  }
  .note-title {
    font-size: 1.8rem;
  }
  .note-data {
    font-size: 1.4rem;
  }

  .grid-footer {
    column-gap: 4.8rem;
  }

  .btn-mobile-nav {
    display: block;
    z-index: 999;
  }
  .main-nav {
    background-color: rgba(255, 255, 255, 0.5);
    /* When put anything absolute should give position:relative to its parent */
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(10px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transform: translateX(100%);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in;
    /* Hide navigation */
    /* Allow NO transition att all */
    /* display: none; */
    /* 1) Hide element visually */
    opacity: 0;
    /* 2) Make it unaccessible to mouse and keyboard */
    pointer-events: none;

    /* 3)Hide it from screen readers */
    visibility: hidden;
  }
  .nav-open .main-nav {
    opacity: 1;
    /* 2) Make it accessible to mouse and keyboard */
    pointer-events: auto;
    /* 3)visible it from screen readers */
    visibility: visible;
    transform: translateX(0);
  }
  .nav-open .icon-mobile-nav[name="close-outline"] {
    display: block;
  }
  .nav-open .icon-mobile-nav[name="menu-outline"] {
    display: none;
  }

  .main-nav-list {
    flex-direction: column;
    gap: 4.8rem;
  }
  .main-nav-link:link,
  .main-nav-link:visited {
    font-size: 3rem;
  }
  .cta-text-box {
    padding: 1.2rem;
  }
  .cta-form {
    column-gap: 1.2rem;
  }
}

/* -------------------------------------------------------------------------- */
/*                               BELOW 850px /16px (Smaller tablet)                 */
/* -------------------------------------------------------------------------- */
@media (max-width: 53em) {
  .heading-primary {
    font-size: 3rem;
  }
  .container.grid.grid-4-cols {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2.4rem;
    row-gap: 2.4rem;
  }
  .grid-row-3-cols {
    grid-column: span 2;
  }
  .product-img {
    width: 50%;
  }
  .product-specifications li {
    font-size: 2.2rem;
  }
  .grid-footer {
    grid-template-columns: repate(6, 1fr);
  }

  .logo-col,
  .address-col {
    grid-column: span 3;
  }
  .nav-col {
    grid-row: 1;
    grid-column: span 2;
  }
  .cta-text-box {
    padding: 1.2rem;
  }
  .cta {
    grid-template-columns: 1.2fr 1fr;
  }
  .cta-form {
    grid-template-columns: 1fr;
  }
  .cta .heading-secondary {
    font-size: 2.4rem;
  }
  .cta-text {
    font-size: 1.6rem;
  }
  .hero-img {
    width: 100%;
  }
}

/* -------------------------------------------------------------------------- */
/*                               BELOW 600px /16px (Smaller tablet)                 */
/* -------------------------------------------------------------------------- */
@media (max-width: 37em) {
  .grid-2-cols,
  .grid-why-us,
  .grid-3-cols {
    grid-template-columns: 1fr;
  }

  .start-order-btns {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .header .compose-logo {
    font-size: 2.4rem;
  }
  .log-title {
    padding-top: 1rem;
  }
  .logo-activity {
    padding-top: 1rem;
  }
  .header .compose-logo p {
    font-size: 1.6rem;
  }
  .logo {
    padding-top: 1rem;
  }

  .btn,
  .btn:link,
  .btn:visited {
    padding: 2.4rem 3.6rem;
  }
  .section-hero {
    padding: 1.6rem 1.2rem;
  }
  .container.grid.grid-4-cols {
    grid-template-columns: 1fr;
  }
  .grid-row-3-cols {
    flex-direction: column;
    grid-column: span 1;
  }

  .hero-img {
    width: 100%;
  }
  /* .start-order-btns {
    flex-direction: column;
    align-items: space-around;
  } */
  .logos {
    grid-template-columns: 1fr 1fr;
  }
  .clients-imgs {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 2rem;
  }
  .cta {
    grid-template-columns: 1fr;
  }
  .cta-image-box {
    height: 32rem;
    grid-row: 1;
  }
  .note-title,
  .abs-title {
    text-align: left;
    font-size: 2.4rem;
  }

  .note-data {
    text-align: left;
    padding-bottom: 1.2rem;
    font-size: 1.8rem;
  }
  .product {
    text-align: center;
  }
  .product-title {
    font-size: 2.8rem;
  }

  .product-specifications {
    align-items: center;
  }
  .section-cta {
    padding-bottom: 2.4rem;
  }
  .cta .heading-secondary {
    font-size: 2rem;
  }
  .cta-text {
    font-size: 1.4rem;
  }
  .footer {
    padding: 4rem 0;
  }
}

/* FONT SIZE SYSTEM (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98
SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128 */
